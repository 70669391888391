export const baseurl = "https://node.fieres.io/"; // live
export const ICOBaseURL = "https://javavesting.fieres.io"; // live

// export const baseurl = "https://node-centralizedexchange.mobiloitte.io/"; // stage
// export const ICOBaseURL = "https://java-iconexarise.mobiloitte.io"; // stage

let user = `${baseurl}api/v1`;
let admin = `${user}/admin`;
let news = `${user}/news`;

const ApiConfigs = {
  // admin Login
  login: `${admin}/login`,
  resendOtp: `${admin}/resendOTP`,
  verifyOTP: `${admin}/verifyOTP`,
  resetPassword: `${admin}/resetPassword`,
  getProfile: `${admin}/getProfile`,
  forgotPassword: `${admin}/forgotPassword`,
  changePassword: `${admin}/changePassword`,

  //Dashboard
  dashboard: `${news}/dashboard`,

  //News Management
  addNews: `${news}/addNews`,
  editNews: `${news}/editNews`,
  listNews: `${news}/listNews`,
  viewNews: `${news}/viewNews`,
  listNewsForAdmin: `${news}/listNewsForAdmin`,
  deleteNews: `${news}/deleteNews`,
  activeBlockNews: `${news}/activeBlockNews`,
};

export default ApiConfigs;
