import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/coming-soon",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/CommingSoon")),
  },
  {
    exact: true,
    path: "/roadmap",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Roadmap")),
  },
  {
    exact: true,
    path: "/faqs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FAQs/Index")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/About")),
  },
  {
    exact: true,
    path: "/token-disclaimer",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/TokenDisclaimer /TokenDisclaimer")
    ),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/T&C/Term")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy/Privacy")),
  },

  {
    exact: true,
    path: "/news-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NewsDetails")),
  },

  // ----------- Admin Routs-----------------

  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/AdminAuth/Login/index")),
  },

  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/AdminAuth/forget-password/index")
    ),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/AdminAuth/reset-password/index")
    ),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/AdminAuth/VerifyOtp")),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AdminDashboard/Dashboard")),
  },
  {
    exact: true,
    guard: true,
    path: "/news-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminDashboard/NewsManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/video-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminDashboard/VideoManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-news",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminDashboard/NewsManagement/AddNewsManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-video",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminDashboard/VideoManagement/AddVideoManagement"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-news",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminDashboard/NewsManagement/VieNewsManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/settings",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminDashboard/Settings/Settings")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminDashboard/Settings/Changepassword")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
